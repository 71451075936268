import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Container, Form, Row, Button } from "react-bootstrap";
import Header from "../components/Header";
import FormSecOne from "../components/FormSecOne";
import FormSecTwo from "../components/FormSecTwo";
import FormSecThree from "../components/FormSecThree";
import FormSecFour from "../components/FormSecFour";
import FormSecFive from "../components/FormSecFive";
import FormSecSix from "../components/FormSecSix";
import FileUploadComp from "../components/FileUploadComp";
import { saveData } from "../utils/api";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import NotificationAlert from "../components/NotificationAlert";
import { validateReport } from "../utils/commonFunc";

const Report = () => {
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    mode: "all",
  });
  const [company, setCompany] = useState({ showOther: false, value: "" });
  const [supportReq, setSupportReq] = useState();
  const [hasSiteCode, setHasSiteCode] = useState(true);
  const [prov, setProv] = useState({ showOther: false, value: "" });
  const [material, setMaterial] = useState("");
  const [unitMeasure, setUnitMeasure] = useState("");
  const [refrigerantType, setRefrigerantType] = useState({
    showOther: false,
    value: "",
  });
  const [substanceType, setSubstanceType] = useState({
    showOther: false,
    value: "",
  });
  const [systemRecharged, setSystemRecharged] = useState("");
  const [substanceReachNature, setSubstanceReachNature] = useState();
  const [substanceDrains, setSubstanceDrains] = useState();
  const [isReported, setIsReported] = useState({
    showInput: false,
    value: "initial",
  });
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isComplete, setIsComplete] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
  });
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [message, setMessage] = useState({ messageType: "", message: "" });
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const navigate = useNavigate();

  // check each section is filled in before showing the next
  const checkForm = (data) => {
    const sectionOne = ["email", "reporter_name", "phone"];
    const sectionThree = [
      "incident_date",
      "incident_time",
      "site_code",
      "address",
      "city",
    ];
    const sectionFiveRefrige = [
      "system_make",
      "system_model",
      "system_serial_num",
      "release_location",
      "charging_capacity",
    ];
    const sectionFiveOther = ["actions_taken_contain", "actions_taken_clean"];
    let isCompleteOne = true;
    let isCompleteThree = true;
    let isCompleteFive = true;

    sectionOne.forEach((field) => {
      if (!data[field] || !data[field].trim()) {
        isCompleteOne = false;
      }
    });

    if (isCompleteOne) {
      setIsComplete((prevState) => ({
        ...prevState,
        1: company.value.trim() ? true : false,
      }));
    }

    if (isComplete[1]) {
      setIsComplete((prevState) => ({
        ...prevState,
        2: supportReq !== null ? true : false,
      }));
    }

    sectionThree.forEach((field) => {
      if (!data[field] || !data[field].trim()) {
        if (!(field === "site_code" && !hasSiteCode)) {
          isCompleteThree = false;
        }
      }
    });
    if (isCompleteThree) {
      if (isComplete[1] && isComplete[2]) {
        setIsComplete((prevState) => ({
          ...prevState,
          3: prov.value.trim() ? true : false,
        }));
      }
    }

    if (isComplete[1] && isComplete[2] && isComplete[3]) {
      setIsComplete((prevState) => ({
        ...prevState,
        4:
          material.trim() &&
            unitMeasure.trim() &&
            data["quantity"] &&
            !isNaN(data["quantity"])
            ? true
            : false,
      }));
    }

    if (isComplete[1] && isComplete[2] && isComplete[3] && isComplete[4]) {
      // isRefrigerant
      if (material.trim() !== "other") {
        sectionFiveRefrige.forEach((field) => {
          if (!data[field] || !data[field].trim()) {
            isCompleteFive = false;
          }
        });

        if (isCompleteFive) {
          setIsComplete((prevState) => ({
            ...prevState,
            5:
              refrigerantType.value.trim() &&
                (systemRecharged === "yes" ||
                  systemRecharged === "no" ||
                  systemRecharged === "n/a")
                ? true
                : false,
          }));
        }
      } else {
        sectionFiveOther.forEach((field) => {
          if (!data[field] || !data[field].trim()) {
            isCompleteFive = false;
          }
        });

        if (isCompleteFive) {
          setIsComplete((prevState) => ({
            ...prevState,
            5:
              substanceReachNature !== null && substanceDrains !== null
                ? true
                : false,
          }));
        }
      }
    }

    if (
      isComplete[1] &&
      isComplete[2] &&
      isComplete[3] &&
      isComplete[4] &&
      isComplete[5]
    ) {
      setIsComplete((prevState) => ({
        ...prevState,
        6:
          data["root_cause"] &&
          data["root_cause"].trim() &&
          isReported.value.trim(),
      }));
    }
  };

  // trigger checkForm() when a text field is changed
  useEffect(() => {
    watch((data) => {
      // console.log(data);
      if (data) checkForm(data);
    });
  }, [watch, isComplete]);

  // trigger checkForm() when a non-text field is changed
  useEffect(() => {
    const data = getValues();
    if (data) checkForm(data);
  }, [
    // isComplete,
    company,
    supportReq,
    hasSiteCode,
    prov,
    material,
    unitMeasure,
    refrigerantType,
    substanceType,
    systemRecharged,
    substanceReachNature,
    substanceDrains,
    isReported,
  ]);

  useEffect(() => {
    if (
      isComplete[1] &&
      isComplete[2] &&
      isComplete[3] &&
      isComplete[4] &&
      isComplete[5] &&
      isComplete[6]
    ) {
      setDisableSubmit(false);
    } else {
      setDisableSubmit(true);
    }
  }, [isComplete]);

  const validateForm = () => {
    const formData = {};
    let completeForm = getValues();

    const refrigerantHookList = [
      "system_make",
      "system_model",
      "system_serial_num",
      "release_location",
      "charging_capacity",
      "actions_taken",
    ];
    const otherHookList = ["actions_taken_contain", "actions_taken_clean"];
    let filterOutList;

    if (material !== "other") {
      filterOutList = otherHookList;
    } else {
      filterOutList = refrigerantHookList;
    }

    Object.keys(completeForm).forEach((key) => {
      if (!filterOutList.includes(key)) formData[key] = completeForm[key];
    });

    formData["company"] = company.value;
    formData["support_req"] = supportReq;
    formData["prov"] = prov.value;
    formData["material"] = material;
    formData["unit_measure"] = unitMeasure;
    if (!hasSiteCode) {
      formData["site_code"] = "n/a";
    }
    if (material !== "other") {
      formData["refrigerant_type"] = refrigerantType.value;
      formData["system_recharged"] = systemRecharged;
    } else {
      formData["substance"] = substanceType.value;
      formData["substance_reach_nature"] = substanceReachNature;
      formData["substance_drains"] = substanceDrains;
    }
    formData["is_reported"] = isReported.value;

    const validation = validateReport(formData);
    if (validation.isValid) {
      setDisableSubmit(true)
      saveData(compileForm(), navigate, setMessage).finally(() => setDisableSubmit(false))
    } else {
      setMessage({ messageType: "danger", message: validation.error });
    }
  };
  // compile all form answers
  const compileForm = () => {
    const formData = new FormData();
    let completeForm = getValues();

    const refrigerantHookList = [
      "system_make",
      "system_model",
      "system_serial_num",
      "release_location",
      "charging_capacity",
      "actions_taken",
    ];
    const otherHookList = ["actions_taken_contain", "actions_taken_clean"];
    let filterOutList;

    if (material !== "other") {
      filterOutList = otherHookList;
    } else {
      filterOutList = refrigerantHookList;
    }

    Object.keys(completeForm).forEach((key) => {
      if (!filterOutList.includes(key)) formData.append(key, completeForm[key]);
    });
    formData.append("language", language.includes("fr") ? "fr" : "en");
    formData.append("company", company.value);
    formData.append("support_req", supportReq);
    formData.append("prov", prov.value);
    formData.append("material", material);
    formData.append("unit_measure", unitMeasure);
    if (!hasSiteCode) {
      formData.set("site_code", "n/a");
    }
    if (material !== "other") {
      formData.append("refrigerant_type", refrigerantType.value);
      formData.append("system_recharged", systemRecharged);
    } else {
      formData.append("substance", substanceType.value);
      formData.append("substance_reach_nature", substanceReachNature);
      formData.append("substance_drains", substanceDrains);
    }
    formData.append("is_reported", isReported.value);
    if (selectedFiles) {
      selectedFiles.forEach((f) => {
        formData.append("file[]", f);
      });
    }
    return formData;
  };

  const handleSave = () => {
    validateForm();
  };

  return (
    <Container>
      <Header variant="secondary" />
      <Row className="mt-3">
        <h6 className="mb-0">{t("title")}</h6>
        <h1>{t("newReport")}</h1>
      </Row>
      <Row>
        <p className="text-warning">{t("instruction")}</p>
      </Row>
      <Form onSubmit={handleSubmit(handleSave)}>
        <FormSecOne
          register={register}
          setValue={setValue}
          getValues={getValues}
          company={company}
          setCompany={setCompany}
          errors={errors}
        />
        {isComplete[1] && (
          <FormSecTwo supportReq={supportReq} setSupportReq={setSupportReq} />
        )}
        {isComplete[1] && isComplete[2] && (
          <FormSecThree
            register={register}
            control={control}
            setValue={setValue}
            getValues={getValues}
            hasSiteCode={hasSiteCode}
            setHasSiteCode={setHasSiteCode}
            prov={prov}
            setProv={setProv}
            errors={errors}
          />
        )}
        {isComplete[1] && isComplete[2] && isComplete[3] && (
          <FormSecFour
            register={register}
            material={material}
            setMaterial={setMaterial}
            setUnitMeasure={setUnitMeasure}
            errors={errors}
          />
        )}
        {isComplete[1] && isComplete[2] && isComplete[3] && isComplete[4] && (
          <FormSecFive
            register={register}
            isRefrigerant={material !== "other"}
            refrigerantType={refrigerantType}
            setRefrigerantType={setRefrigerantType}
            substanceType={substanceType}
            setSubstanceType={setSubstanceType}
            setSystemRecharged={setSystemRecharged}
            substanceReachNature={substanceReachNature}
            setSubstanceReachNature={setSubstanceReachNature}
            setSubstanceDrains={setSubstanceDrains}
            substanceDrains={substanceDrains}
            errors={errors}
          />
        )}
        {isComplete[1] &&
          isComplete[2] &&
          isComplete[3] &&
          isComplete[4] &&
          isComplete[5] && (
            <>
              <FormSecSix
                register={register}
                isRefrigerant={material !== "other"}
                isReported={isReported}
                setIsReported={setIsReported}
                errors={errors}
              />
              <FileUploadComp
                selectedFiles={selectedFiles}
                onSelectedFiles={setSelectedFiles}
              />
            </>
          )}
        <Button
          className="btn-primary-green mt-4"
          style={{ display: "block" }}
          disabled={disableSubmit}
          // onClick={() => handleSave()}
          type="submit"
        >
          {t("submit")}
        </Button>
      </Form>
      {message.message && (
        <div className="mt-3">
          <NotificationAlert
            varient={message.messageType}
            message={t(message.message)}
            onClose={() => setMessage({ message: "" })}
          />
        </div>
      )}
    </Container>
  );
};

export default Report;
