import { Form, FormGroup } from "react-bootstrap";
import { Trans, withTranslation, useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { BrandColours } from "../lib/brandColours";

const FormSecSix = (props) => {
	const { t } = useTranslation();

	return (
		<>
			<FormGroup>
				<h5>{t("additionalSpillDetails")}</h5>
				{props.isRefrigerant && (
					<>
						<Form.Label>{t("actionsTaken")}</Form.Label>
						<Form.Control
							{...props.register("actions_taken", {
								required: {
									value: true,
									message: t("requiredField"),
								},
							})}
							required
							type="text"
							id="actionsTaken"
						// placeholder="Enter actions taken"
						/>
						{props.errors?.actions_taken?.message && (
							<p className="text-invalid">{props.errors.actions_taken?.message}</p>
						)}
					</>
				)}
				<Form.Label>{t("rootCause")}</Form.Label>
				<Form.Control
					{...props.register("root_cause", {
						required: {
							value: true,
							message: t("requiredField"),
						},
					})}
					required
					as="textarea"
					id="rootCause"
				/>
				{props.errors?.root_cause?.message && (
					<p className="text-invalid">{props.errors.root_cause?.message}</p>
				)}
				<Form.Label>
					<Trans>hasBeenReported</Trans>
				</Form.Label>
				<p className="text-help">
					<FontAwesomeIcon
						icon={faCircleInfo}
						size="lg"
						color={BrandColours.purpleEminence}
						className="me-1"
					/>
					{t("hasBeenReportedInfo")}
				</p>
				<div></div>
				<Form.Check
					required
					inline
					label={t("yes")}
					type="radio"
					id="reportedYes"
					name="reported"
					checked={
						props.isReported.value !== "initial" &&
						props.isReported.value !== "no"
					}
					onChange={() =>
						props.setIsReported({
							showInput: true,
							value: "",
						})
					}
				/>
				<Form.Check
					required
					inline
					label={t("no")}
					type="radio"
					id="reportedNo"
					name="reported"
					checked={props.isReported.value === "no"}
					onChange={() =>
						props.setIsReported({
							showInput: false,
							value: "no",
						})
					}
				/>
				{props.isReported.showInput && (
					<Form.Control
						required={props.isReported.showInput}
						type="text"
						placeholder={t("enterDetails")}
						value={props.isReported.value}
						onChange={(e) => {
							props.setIsReported({
								showInput: true,
								value: e.target.value,
							});
						}}
					/>
				)}
				<div></div>
				<Form.Label>{t("otherDetails")}</Form.Label>
				<Form.Control
					{...props.register("other_details")}
					as="textarea"
					id="otherDetails"
				/>
			</FormGroup>
		</>
	);
};

export default withTranslation()(FormSecSix);
