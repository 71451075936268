import { Col, Row, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { BrandColours } from "../lib/brandColours";

let URL = process.env.REACT_APP_ADMIN_URL;

if (window.location.hostname.includes("localhost")) {
	URL = "http://localhost:8080";
}
else if (window.location.hostname.includes("np")) {
	URL = "https://spillreporting-np.cloudapps.telus.com";
}
else {
	URL = "https://spillreporting.cloudapps.telus.com";
}

const LangUserBlock = () => {
	const { i18n } = useTranslation();
	const currentLang = i18n.language.toUpperCase();

	const changeLang = (e) => {
		i18n.changeLanguage(e.target.value);
	};
	return (
		<>
			<Col>
				<a href={URL}>
					<FontAwesomeIcon
						icon={faUser}
						size="lg"
						color={BrandColours.greyThunder}
						aria-labelledby="admin"
					/>
				</a>
			</Col>
			<Col>
				<Button
					id="btn-lang"
					className="btn-text-only pe-0"
					onClick={changeLang}
					value={currentLang === "EN" ? "fr" : "en"}
				>
					{currentLang === "EN" ? "FR" : "EN"}
				</Button>
			</Col>
		</>
	);
};

const Header = (props) => {
	const { t } = useTranslation();

	return (
		<>
			{props.variant === "primary" && (
				<>
					<Row className="float-end">
						<LangUserBlock />
					</Row>
					<Row className="text-center">
						<Link to={`/`} className="link-inline">
							<img
								className="logo-vert"
								src="/TELUS_EN_Hor_RGB.svg"
								alt="TELUS Logo"
							/>
						</Link>
						<h1>{t("title")}</h1>
					</Row>
				</>
			)}
			{props.variant === "secondary" && (
				<Row className="d-flex flex-row justify-content-between">
					<Col xs={10}>
						<Link to={`/`} className="link-inline">
							<img
								className="logo-vert"
								src="/TELUS_EN_Hor_RGB.svg"
								alt="TELUS Logo"
							/>
						</Link>
					</Col>
					<Col xs={2}>
						<Row className="float-end">
							<LangUserBlock />
						</Row>
					</Col>
				</Row>
			)}
		</>
	);
};

export default Header;
