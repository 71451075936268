import {
	commonRequiredFields,
	refrigerantRequiredFields,
	substanceRequiredFields,
} from "./constants";

export const dropdownShowOther = (newInput, setFunc) => {
	if (newInput === "other") {
		setFunc((prev) => ({
			showOther: true,
			value: "",
		}));
	} else {
		setFunc((prev) => ({
			showOther: false,
			value: newInput,
		}));
	}
};

export const validateReport = (data) => {
	let isValid = true;
	let requiredFields;
	let error = "Bad Request: ";
	const dateRe = /^\d{4}-\d{2}-\d{2}$/;
	const timeRe = /^\d{2}:\d{2}$/;
	const emailRe = /^\S+@\S+\.\S+$/;
	const phoneRe = /^\d{3}-\d{3}-\d{4}$/;

	if (data.material === "refrigerant") {
		requiredFields = commonRequiredFields.concat(refrigerantRequiredFields);
	} else {
		requiredFields = commonRequiredFields.concat(substanceRequiredFields);
	}
	requiredFields.forEach((field) => {
		if (!(field in data) || data[field] === "") {
			isValid = false;
			error += `Missing Field: ${field}. \n`;
		}
		if (field === "email" && !emailRe.test(data[field])) {
			isValid = false;
			error += "Invalid email format. \n";
		}
		if (field === "phone" && !phoneRe.test(data[field])) {
			isValid = false;
			error += "Phone number must be in the format XXX-XXX-XXXX. \n";
		}
		if (field === "incident_date" && !dateRe.test(data[field])) {
			isValid = false;
			error += "Date must be in the format yyyy-mm-dd. \n";
		}
		if (field === "incident_time" && !timeRe.test(data[field])) {
			isValid = false;
			error += "Time must be in the format HH:mm. \n";
		}
	});

	return { isValid: isValid, error: error };
};

export const round = (num, precision) =>
	Number(Math.round(num + "e+" + precision) + "e-" + precision);

export const phoneNumberAutoFormat = (phoneNumber, prevNumber) => {
	const number = phoneNumber.trim().replace(/[^0-9]/g, "");
	if (prevNumber.length < phoneNumber.length) {
		if (number.length < 3) return number;
		if (number.length < 6) return number.replace(/(\d{3})/, "$1-");
		if (number.length < 10) return number.replace(/(\d{3})(\d{3})/, "$1-$2-");
		return number.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
	} else {
		if (number.length >= 10) return number.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
		if (number.length < 10 && number.length >= 7) return number.replace(/(\d{3})(\d{3})/, "$1-$2-");
		if (number.length < 7 && number.length >= 4) return number.replace(/(\d{3})/, "$1-");
		return number;
	}
};

export const checkAndSetNow = (incidentTime, incidentDate) => {
	const now = new Date();
	const input = Date.parse(`${incidentDate}T${incidentTime}`);
	if (input > now) {
		return `${now.getHours()}:${now.getMinutes()}`;
	}
	else {
		return incidentTime;
	}
};