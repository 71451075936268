import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useRef } from "react";
import { BrandColours } from "../lib/brandColours";
import { round } from "../utils/commonFunc";
import { Form, FormGroup, Tooltip, OverlayTrigger } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import NotificationAlert from "./NotificationAlert";

const acceptableFileTypes = [
  "image",
  "doc",
  "docx",
  "office",
  "pdf",
  "text",
  "txt",
];

const TEN_MEGABYTES = 10 * 1024 * 1024;

const isFileAcceptable = (file) =>
  file.size < TEN_MEGABYTES &&
  acceptableFileTypes.some((substring) => file.type.includes(substring));

const FileUploadComp = ({ onSelectedFiles }) => {
  const target = useRef(null);

  const [filesInfo, setFilesInfo] = useState("");
  const [rejectedFilesInfo, setRejectedFilesInfo] = useState("");

  const { t } = useTranslation();

  const changeHandler = (e) => {
    setRejectedFilesInfo((previous) => previous && "");
    const files = [...e.target.files];
    const acceptedFiles = [];
    const rejectedFiles = [];

    for (const file of files) {
      if (isFileAcceptable(file) && acceptedFiles.length < 10)
        acceptedFiles.push(file);
      else rejectedFiles.push(file);
    }

    setRejectedFilesInfo(rejectedFiles.map((file) => file.name).join(", "));

    setFilesInfo(
      acceptedFiles
        .map((file) => `${file.name} (${round(file.size / 1024, 1)}kb)`)
        .join(", ")
    );

    onSelectedFiles(acceptedFiles);
  };

  const toolTipAttachment = (props) => {
    return (
      <Tooltip id="tooltip-attachment" {...props}>
        {t("toolTipAttachment")}
      </Tooltip>
    );
  };

  return (
    <FormGroup>
      <Form.Label>
        {t("attachDoc")}
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={toolTipAttachment}
          trigger={["hover", "focus", "click"]}
        >
          <FontAwesomeIcon
            icon={faInfoCircle}
            size="lg"
            color={BrandColours.purpleEminence}
            ref={target}
          />
        </OverlayTrigger>
        <br />
        {t("attachDocSecondary")}
      </Form.Label>
      <div></div>
      <input
        type="file"
        name="file"
        onChange={changeHandler}
        multiple
        accept="image/*,.doc,.docx,.pdf"
      />

      {filesInfo && <p className="mt-2 text-help">{filesInfo}</p>}

      {rejectedFilesInfo && (
        <div className="mt-3">
          <NotificationAlert
            varient={"danger"}
            message={`${t("badFilesText")} ${rejectedFilesInfo}`}
            onClose={() => setRejectedFilesInfo("")}
          />
        </div>
      )}
    </FormGroup>
  );
};

export default FileUploadComp;
