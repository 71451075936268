import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import App from "./App";
import Report from "./route/Report";
import Success from "./route/Success";
import "./i18n/config";
import Update from "./route/Update";
import ExistingReport from "./route/ExistingReport";
import { useTranslation } from "react-i18next";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/report",
    element: <Report />,
  },
  {
    path: "/editReport",
    element: <ExistingReport />,
  },
  {
    path: "/update",
    element: <Update />,
  },
  {
    path: "/success",
    element: <Success />,
  },
]);

const Root = () => {
  const { t } = useTranslation();
  const pageTitle = t("pageTitle");

  useEffect(() => {
    document.title = pageTitle;
  }, [pageTitle]);

  return (
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
};

ReactDOM.createRoot(document.getElementById("root")).render(<Root />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
