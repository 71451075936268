export const BrandColours = {
	amber: "#faca69",
	amberDark: "#b4872c",
	amberLight: "#fff9ee",
	black: "#000000",
	greenAccessible: "#2b8000",
	greenDarkFern: "#163e06",
	greenPanache: " #f4f9f2",
	greenSanFelix: "#1f5c09",
	greenTelus: "#66cc00",
	greenTusk: "#e3f6d1",
	greenYellow: "#bfe797",
	greyAlabaster: "#fafafa",
	greyAthens: "#f4f4f7",
	greyCharcoal: "#414547",
	greyCloud: "#b2b9bf",
	greyMystic: "#e3e6e8",
	greyShuttle: "#676e73",
	greyThunder: "#2c2e30",
	purpleDark: "#3f2a54",
	purpleDeluge: "#7c53a5",
	purpleEminence: "#613889",
	purpleTelus: "#4b286d",
	red: "#e12339",
	redDark: "#c12335",
	redDarker: "#560c0c",
	redLight: "#fff6f8",
	white: "#ffffff",
};
