// css imports
import "bootstrap/dist/css/bootstrap.min.css";
import "../lib/main.css";
// library imports
import { Container, Row } from "react-bootstrap";
import Header from "../components/Header";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const Success = () => {
	const { t } = useTranslation();
	const location = useLocation();

	return (
		<Container>
			<Header variant="secondary" />
			<Row className="mt-4 d-flex flex-row justify-content-center">
				<h1>{t("thankYou")}</h1>
				<h6>{t("thankYouNote")}</h6>
				{location.state.message && (
					<h6 className="text-warning">{location.state.message}</h6>
				)}
				<h6>
					{t("refIdNote")}
					{location.state.uniqueId}
				</h6>
				<h6 className="text-warning">{t("reminderEmergency")}</h6>
			</Row>
		</Container>
	);
};

export default Success;
