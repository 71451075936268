import { Col, Form, FormGroup, Row } from "react-bootstrap";
import { dropdownShowOther, checkAndSetNow } from "../utils/commonFunc";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { BrandColours } from "../lib/brandColours";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
import { useState, useEffect } from "react";

const rangeFrom00to12 = [...Array(13).keys()];
rangeFrom00to12.shift();

const hours = rangeFrom00to12;
const minutes = [...Array(60).keys()];
const amPm = ["AM", "PM"];

const marshallValue = (hours, minutes, period) => {
  let parsedHours = parseInt(hours);
  if (period === "AM" && parsedHours === 12) parsedHours = 0;
  if (period === "PM" && parsedHours !== 12) parsedHours += 12;

  const paddedHours = parsedHours.toString().padStart(2, "0");
  const paddedMinutes = minutes.toString().padStart(2, "0");

  return `${paddedHours}:${paddedMinutes}`;
};

const unmarshallValue = (time) => {
  const [hours, minutes] = time.split(":");
  const parsedHours = parseInt(hours);
  const parsedMinutes = parseInt(minutes);

  const period = parsedHours < 12 ? "AM" : "PM";
  const hour = parsedHours % 12 === 0 ? 12 : parsedHours % 12;

  return {
    hour,
    minutes: parsedMinutes,
    period,
  };
};

const inputTimeOrCurrentIfInFuture = (time, date) => {
  const input = Date.parse(`${date}T${time}`);
  const now = new Date();
  if (input > now) {
    const paddedHours = now.getHours().toString().padStart(2, "0");
    const paddedMinutes = now.getMinutes().toString().padStart(2, "0");
    return `${paddedHours}:${paddedMinutes}`;
  } else {
    return time;
  }
};

/* Date selected is needed because we want to know if the user is trying to se
the time in the future, if this happens, we simply set the time to current */
const GovernmentTimePicker = ({ value, onChange, onBlur, dateSelected }) => {
  const [selectedHour, setSelectedHour] = useState("8");
  const [selectedMinute, setSelectedMinute] = useState("0");
  const [selectedPeriod, setSelectedPeriod] = useState("AM");

  const handleSelectedHourChange = (e) => {
    const timeToSet = marshallValue(
      e.target.value,
      selectedMinute,
      selectedPeriod
    );
    onChange(inputTimeOrCurrentIfInFuture(timeToSet, dateSelected));
  };

  const handleSelectedMinuteChange = (e) => {
    const timeToSet = marshallValue(
      selectedHour,
      e.target.value,
      selectedPeriod
    );
    onChange(inputTimeOrCurrentIfInFuture(timeToSet, dateSelected));
  };

  const handleSelectedPeriodChange = (e) => {
    const timeToSet = marshallValue(
      selectedHour,
      selectedMinute,
      e.target.value
    );
    onChange(inputTimeOrCurrentIfInFuture(timeToSet, dateSelected));
  };

  useEffect(() => {
    const { hour, minutes, period } = unmarshallValue(value);
    setSelectedHour(hour);
    setSelectedMinute(minutes);
    setSelectedPeriod(period);
  }, [value]);

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "80px 80px 80px",
        gap: "8px",
      }}
    >
      <Form.Select
        aria-label="hour"
        id="hour"
        value={selectedHour}
        onChange={handleSelectedHourChange}
        onBlur={onBlur}
      >
        {hours.map((hour) => (
          <option key={hour} value={hour}>
            {hour}
          </option>
        ))}
      </Form.Select>

      <Form.Select
        aria-label="minute"
        id="minute"
        value={selectedMinute}
        onChange={handleSelectedMinuteChange}
        onBlur={onBlur}
      >
        {minutes.map((minute) => (
          <option key={minute} value={minute}>
            {minute.toString().padStart(2, "0")}
          </option>
        ))}
      </Form.Select>

      <Form.Select
        aria-label="period"
        id="period"
        value={selectedPeriod}
        onChange={handleSelectedPeriodChange}
        onBlur={onBlur}
      >
        {amPm.map((period) => (
          <option key={period} value={period}>
            {period}
          </option>
        ))}
      </Form.Select>
    </div>
  );
};

const FormSecThree = (props) => {
  const { t } = useTranslation();

  const ProvincesTerritories = {
    AB: "Alberta",
    BC: t("bc"),
    MB: "Manitoba",
    NB: t("nb"),
    NL: t("nl"),
    NT: "Northwest Territories",
    NS: t("ns"),
    NU: "Nunavut",
    ON: "Ontario",
    PE: t("pe"),
    QC: "Quebec",
    SK: "Saskatchewan",
    YT: "Yukon",
  };

  let prov = props.prov.value;
  if (prov !== "" && !(prov in ProvincesTerritories)) {
    prov = "other";
  }

  return (
    <>
      <FormGroup>
        <h5>{t("incidentDetails")}</h5>
        {/* TODO: replace with datepicker with time component that can be styled */}
        <Row>
          <Col>
            <Form.Label>{t("incidentDate")}</Form.Label>
            <Form.Control
              {...props.register("incident_date", {
                required: {
                  value: true,
                  max: new Date().toISOString().slice(0, 10),
                  message: t("requiredField"),
                },
              })}
              required
              type="date"
              id="date"
              max={new Date().toISOString().slice(0, 10)}
            />
            {props.errors?.incident_date?.message && (
              <p className="text-invalid">
                {props.errors.incident_date?.message}
              </p>
            )}
          </Col>
          <Col>
            <Form.Label>{t("incidentTime")}</Form.Label>
            <Controller
              control={props.control}
              name="incident_time"
              defaultValue={"00:00"}
              render={({ field: { onChange, onBlur, value, name } }) => {
                return (
                  <GovernmentTimePicker
                    value={value}
                    onBlur={onBlur}
                    dateSelected={props.getValues("incident_date")}
                    onChange={onChange}
                  ></GovernmentTimePicker>
                );
              }}
            />
            {props.errors?.incident_time?.message && (
              <p className="text-invalid">
                {props.errors.incident_time?.message}
              </p>
            )}
          </Col>
        </Row>
        <Form.Label>{t("siteCode")}</Form.Label>
        <Row className="d-flex">
          <p className="text-help">
            <FontAwesomeIcon
              icon={faCircleInfo}
              size="lg"
              color={BrandColours.purpleEminence}
              className="me-1"
            />
            {t("sideCodeInfo")}
          </p>
          <Col xs={10}>
            <Form.Control
              {...props.register("site_code")}
              required={props.hasSiteCode}
              type="text"
              id="siteCode"
              // placeholder={t("enterSiteCode")}
            />
          </Col>
          <Col xs={2} className="align-self-center">
            <Form.Check
              onChange={() => props.setHasSiteCode(!props.hasSiteCode)}
              required={!props.hasSiteCode}
              inline
              label={t("n/a")}
              type="checkbox"
              id="noSiteCode"
              name="siteCodeNA"
              checked={!props.hasSiteCode}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Label>{t("incidentAddress")}</Form.Label>
            <Form.Control
              {...props.register("address", {
                required: {
                  value: true,
                  message: t("requiredField"),
                },
              })}
              required
              type="text"
              id="address"
              // placeholder={t("enterAddress")}
              placeholder="123 Sample St"
            />
            {props.errors?.address?.message && (
              <p className="text-invalid">{props.errors.address?.message}</p>
            )}
          </Col>
          <Col>
            <Form.Label>{t("incidentCity")}</Form.Label>
            <Form.Control
              {...props.register("city", {
                required: {
                  value: true,
                  message: t("requiredField"),
                },
              })}
              required
              type="text"
              id="city"
              placeholder={t("enterCity")}
            />
            {props.errors?.city?.message && (
              <p className="text-invalid">{props.errors.city?.message}</p>
            )}
          </Col>
        </Row>
        <Row className="d-flex">
          <Col>
            <Form.Label>{t("incidentProv")}</Form.Label>
            <Form.Select
              required
              aria-label="provinceOfIncident"
              id="prov"
              value={prov}
              onChange={(e) => {
                dropdownShowOther(e.target.value, props.setProv);
              }}
            >
              <option>{t("selectProv")}</option>
              {Object.keys(ProvincesTerritories).map((code) => (
                <option key={code} value={code}>
                  {ProvincesTerritories[code]}
                </option>
              ))}
              <option value="other">{t("other")}</option>
            </Form.Select>
          </Col>
          {(props.prov.showOther || prov === "other") && (
            <Col className="align-self-end">
              <Form.Control
                className="mt-2"
                required={props.prov.showOther}
                type="text"
                id="showProvOther"
                value={props.prov.value}
                placeholder={t("specifyLocation")}
                onChange={(e) =>
                  props.setProv((prevState) => ({
                    ...prevState,
                    value: e.target.value,
                  }))
                }
              />
            </Col>
          )}
        </Row>
      </FormGroup>
      <hr />
    </>
  );
};

export default FormSecThree;
