import axios from "axios";
import { Buffer } from "buffer";
import i18next from "i18next";
let URL = process.env.REACT_APP_BE_URL;

if (window.location.hostname.includes("localhost")) {
  URL = "http://localhost:8080/api";
} else if (window.location.hostname.includes("np")) {
  URL = "https://np.spillreporting.telus.com/api";
} else {
  URL = "https://spillreporting.telus.com/api";
}

//Fetch does not enter catch block for 4xx/5xx responses, but axios does.
export const getFiles = async (id, setAttachments, setMessage) => {
  try {
    const res = await axios({
      method: "GET",
      url: `${URL}/files/${id}`,
    });

    if (res.status === 200) {
      if (res.data.foundFiles) {
        const files = [];
        res.data.files.forEach(async (f) => {
          files.push({ name: f.name, src: `${URL}/file/${f.name}` });
        });
        return setAttachments({
          show: true,
          foundFiles: true,
          files: [...files],
        });
      } else {
        return setAttachments({ show: true, foundFiles: false });
      }
    }
  } catch (err) {
    setMessage({
      messageType: err.response.data.messageType,
      message: err.response.data.description,
    });
  }
};

export const getReport = async (id, navigate, setMessage) => {
  try {
    let res = await fetch(`${URL}/reports/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    let result = await res.json();

    if (res.status === 200) {
      if (result.length > 0) {
        navigate("/editReport", { state: { data: result[0], id: id } });
      } else {
        setMessage({
          messageType: "warning",
          message:
            "No report found. Please check you have the correct Reference ID.",
        });
      }
    } else {
      setMessage({
        messageType: result.messageType,
        message: result.description,
      });
    }
  } catch (err) {
    setMessage({
      messageType: "danger",
      message: "Unexpected error. Try again later, or contact support.",
    });
  }
};

// POST
export const saveData = async (data, navigate, setMessage) => {
  let uniqueId;
  const currentLang = i18next.resolvedLanguage.toUpperCase();
  console.log(currentLang);

  try {
    let res = await fetch(`${URL}/reports`, {
      method: "POST",
      body: data,
      headers: {
        lang: currentLang,
      },
    });
    let result = await res.json();

    if (res.status === 201) {
      uniqueId = result.data;
      if (result.messageType === "success") {
        navigate("/success", { state: { uniqueId: uniqueId } });
      } else {
        navigate("/success", {
          state: { uniqueId: uniqueId, message: result.message },
        });
      }
    } else {
      setMessage({
        messageType: result.messageType,
        message: result.description,
      });
    }
  } catch (err) {
    setMessage({
      messageType: "danger",
      message: "Unexpected error. Try again later, or contact support.",
    });
  }
};

// PUT
export const updateReport = async (id, data, navigate, setMessage) => {
  let uniqueId;
  const currentLang = i18next.resolvedLanguage.toUpperCase();
  console.log(currentLang);

  try {
    let res = await fetch(`${URL}/reports/${id}`, {
      method: "PUT",
      body: data,
      headers: {
        lang: currentLang,
      },
    });
    let result = await res.json();

    if (res.status === 200) {
      uniqueId = result.data;
      if (result.messageType === "success") {
        console.log(uniqueId);
        navigate("/success", { state: { uniqueId: uniqueId } });
      } else {
        console.log(result.message);
        navigate("/success", {
          state: { uniqueId: uniqueId, message: result.message },
        });
      }
    } else {
      setMessage({
        messageType: result.messageType,
        message: result.description,
      });
    }
  } catch (err) {
    setMessage({
      messageType: "danger",
      message: "Unexpected error. Try again later, or contact support.",
    });
  }
};
