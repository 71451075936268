import {
	faCircleCheck,
	faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert } from "react-bootstrap";
// import "../lib/App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrandColours } from "../lib/brandColours";

const varientIcons = {
	success: faCircleCheck,
	danger: faTriangleExclamation,
	warning: faTriangleExclamation,
};

const varientColours = {
	success: BrandColours.greenAccessible,
	danger: BrandColours.red,
	warning: BrandColours.amberDark,
};

const NotificationAlert = (props) => {
	const varient = props.varient;

	return (
		<Alert
			className={`alert-${varient}`}
			dismissible="true"
			onClose={props.onClose}
		>
			<div className="d-flex flex-row align-items-center">
				<div>
					<FontAwesomeIcon
						icon={varientIcons[varient]}
						size="lg"
						color={varientColours[varient]}
						className="me-2"
					/>
				</div>
				<div className="flex-fill pe-5">{props.message}</div>
			</div>
		</Alert>
	);
};

export default NotificationAlert;
