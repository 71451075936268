import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Container, Form, Row, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Header from "../components/Header";
import FormSecOne from "../components/FormSecOne";
import FormSecTwo from "../components/FormSecTwo";
import FormSecThree from "../components/FormSecThree";
import FormSecFour from "../components/FormSecFour";
import FormSecFive from "../components/FormSecFive";
import FormSecSix from "../components/FormSecSix";
import FileUploadComp from "../components/FileUploadComp";
import { getFiles, updateReport } from "../utils/api";
import NotificationAlert from "../components/NotificationAlert";

const ExistingReport = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const data = location.state.data;
  const id = location.state.id;
  const versionNum = location.state.data.version_num || 0;
  const {
    register,
    getValues,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: data.email,
      reporter_name: data.reporter_name,
      phone: data.phone,
      incident_date: data.incident_date,
      incident_time: data.incident_time,
      address: data.address,
      city: data.city,
      site_code: data.site_code === "n/a" ? "" : data.site_code,
      quantity: data.quantity,
      actions_taken_contain: data.actions_taken_contain,
      actions_taken_clean: data.actions_taken_clean,
      system_make: data.system_make,
      system_model: data.system_model,
      system_serial_num: data.system_serial_num,
      release_location: data.release_location,
      charging_capacity: data.charging_capacity,
      actions_taken: data.actions_taken,
      root_cause: data.root_cause,
      other_details: data.other_details,
    },
  });
  const [company, setCompany] = useState({
    showOther: false,
    value: data.company,
  });
  const [supportReq, setSupportReq] = useState(
    data.support_req || !data.support_req ? data.support_req : ""
  );
  const [hasSiteCode, setHasSiteCode] = useState(data.site_code !== "n/a");
  const [prov, setProv] = useState({
    showOther: false,
    value: data.prov,
  });
  const [material, setMaterial] = useState(data.material);
  const [unitMeasure, setUnitMeasure] = useState(data.unit_measure);
  const [refrigerantType, setRefrigerantType] = useState({
    showOther: false,
    value: data.refrigerant_type,
  });
  const [substanceType, setSubstanceType] = useState({
    showOther: false,
    value: data.substance,
  });
  const [systemRecharged, setSystemRecharged] = useState(data.system_recharged);
  const [substanceReachNature, setSubstanceReachNature] = useState(
    data.substance_reach_nature || !data.substance_reach_nature
      ? data.substance_reach_nature
      : ""
  );
  const [substanceDrains, setSubstanceDrains] = useState(
    data.substance_drains || !data.substance_drains ? data.substance_drains : ""
  );
  const [isReported, setIsReported] = useState({
    showInput: data.is_reported && data.is_reported !== "no",
    value: data.is_reported,
  });
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [message, setMessage] = useState({ messageType: "", message: "" });
  const [attachmentsMessage, setAttachmentsMessage] = useState({
    messageType: "",
    message: "",
  });
  const [attachments, setAttachments] = useState({ show: false });

  // compile all form answers
  const compileForm = () => {
    const formData = new FormData();
    let completeForm = getValues();

    const refrigerantHookList = [
      "system_make",
      "system_model",
      "system_serial_num",
      "release_location",
      "charging_capacity",
      "actions_taken",
    ];
    const otherHookList = ["actions_taken_contain", "actions_taken_clean"];
    let filterOutList;

    if (material !== "other") {
      filterOutList = otherHookList;
    } else {
      filterOutList = refrigerantHookList;
    }

    Object.keys(completeForm).forEach((key) => {
      if (!filterOutList.includes(key)) formData.append(key, completeForm[key]);
    });
    formData.append("language", language.includes("fr") ? "fr" : "en");
    formData.append("company", company.value);
    formData.append("support_req", supportReq);
    formData.append("prov", prov.value);
    formData.append("material", material);
    formData.append("unit_measure", unitMeasure);
    if (!hasSiteCode) {
      formData.set("site_code", "n/a");
    }
    if (material !== "other") {
      formData.append("refrigerant_type", refrigerantType.value);
      formData.append("system_recharged", systemRecharged);
    } else {
      formData.append("substance", substanceType.value);
      formData.append("substance_reach_nature", substanceReachNature);
      formData.append("substance_drains", substanceDrains);
    }
    formData.append("is_reported", isReported.value);
    if (selectedFiles) {
      selectedFiles.forEach((f) => {
        formData.append("file[]", f);
      });
    }
    formData.append("version_num", versionNum + 1);
    return formData;
  };

  const handleSubmit = () => {
    updateReport(id, compileForm(), navigate, setMessage);
  };

  const getAttachments = () => {
    getFiles(data.ref_id, setAttachments, setAttachmentsMessage);
  };

  return (
    <Container>
      <Header variant="secondary" />
      <Row className="mt-3">
        <h6 className="mb-0">{t("title")}</h6>
        <h1>{t("updateReport")}</h1>
      </Row>
      {/* <Row>
				<p className="text-warning">{t("instruction")}</p>
			</Row> */}
      <Form>
        <FormSecOne
          register={register}
          company={company}
          setCompany={setCompany}
        />
        <FormSecTwo supportReq={supportReq} setSupportReq={setSupportReq} />
        <FormSecThree
          register={register}
          hasSiteCode={hasSiteCode}
          setHasSiteCode={setHasSiteCode}
          prov={prov}
          getValues={getValues}
          control={control}
          setProv={setProv}
        />
        <FormSecFour
          register={register}
          material={material}
          setMaterial={setMaterial}
          unitMeasure={unitMeasure}
          disabled={true}
          setUnitMeasure={setUnitMeasure}
        />
        <FormSecFive
          register={register}
          isRefrigerant={data.refrigerant_type !== null}
          refrigerantType={refrigerantType}
          setRefrigerantType={setRefrigerantType}
          substanceType={substanceType}
          setSubstanceType={setSubstanceType}
          systemRecharged={systemRecharged}
          setSystemRecharged={setSystemRecharged}
          substanceReachNature={substanceReachNature}
          setSubstanceReachNature={setSubstanceReachNature}
          substanceDrains={substanceDrains}
          setSubstanceDrains={setSubstanceDrains}
        />
        <FormSecSix
          register={register}
          isRefrigerant={data.refrigerant_type !== null}
          isReported={isReported}
          setIsReported={setIsReported}
        />
        <hr />
        <h5>{t("Attachments")}</h5>

        <Button
          className="btn-text-only mt-3 ps-0"
          onClick={() => {
            getAttachments();
          }}
          disabled={attachments.show}
        >
          {t("retrieveAttachments")}
        </Button>
        {attachmentsMessage.message && (
          <div className="mt-3">
            <NotificationAlert
              varient={attachmentsMessage.messageType}
              message={attachmentsMessage.message}
              onClose={() => setAttachmentsMessage({ message: "" })}
            />
          </div>
        )}
        {attachments.show &&
          attachments.foundFiles &&
          attachments.files.map(({ src, name }) => {
            return (
              <li key={name}>
                <a href={src} download={name}>
                  {name}
                </a>
              </li>
            );
          })}
        {attachments.show && !attachments.foundFiles && (
          <p>{t("noAttachmentsFound")}</p>
        )}
        <FileUploadComp
          selectedFiles={selectedFiles}
          onSelectedFiles={setSelectedFiles}
        />
        <Button
          className="btn-primary-green mt-4"
          style={{ display: "block" }}
          // type="submit"
          disabled={disableSubmit}
          onClick={() => handleSubmit()}
        >
          {t("submit")}
        </Button>
      </Form>
      {message.message && (
        <div className="mt-3">
          <NotificationAlert
            varient={message.messageType}
            message={t(message.message)}
            onClose={() => setMessage({ message: "" })}
          />
        </div>
      )}
    </Container>
  );
};

export default ExistingReport;
