import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { Container, Form, Col, Row, Button } from "react-bootstrap";
import Header from "../components/Header";
import { getReport } from "../utils/api";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import NotificationAlert from "../components/NotificationAlert";

const Update = () => {
	const {
		register,
		watch,
		getValues,
		formState: { errors },
	} = useForm();
	const [disableSubmit, setDisableSubmit] = useState(true);
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const [message, setMessage] = useState({ messageType: "", message: "" });

	useEffect(() => {
		watch((data) => {
			if (data["reportCode"] && data["reportCode"].trim()) {
				setDisableSubmit(false);
			} else {
				setDisableSubmit(true);
			}
		});
	}, [watch]);

	useEffect(() => {
		const params = Object.fromEntries([...searchParams]);
		console.log(JSON.stringify(params));
		if (params["reportCode"]) {
			getReport(params["reportCode"], navigate, setMessage);
		}
	}, [searchParams]);

	const handleSubmit = () => {
		const data = getValues();
		getReport(data["reportCode"], navigate, setMessage);
	};

	return (
		<Container>
			<Header variant="secondary" />
			<Row className="mt-3">
				<h6 className="mb-0">{t("title")}</h6>
				<h1>{t("updateReport")}</h1>
			</Row>
			<Form>
				<Trans i18nKey="updateInstruction">
					<a href={t("contactEmail")}></a>
				</Trans>
				{/* <h5>{t("updateInstruction")}</h5> */}
				<Row>
					<Col>
						<Form.Label>{t("reportCode")}</Form.Label>
						<Form.Control
							{...register("reportCode")}
							required
							type="text"
							id="reportCode"
							placeholder={t("enterReportCode")}
						/>
					</Col>
				</Row>
				<Button
					className="btn-primary-green mt-4"
					style={{ display: "block" }}
					// type="submit"
					disabled={disableSubmit}
					onClick={() => handleSubmit()}
				>
					{t("submit")}
				</Button>
				{message.message && (
					<div className="mt-3">
						<NotificationAlert
							varient={message.messageType}
							message={message.message}
							onClose={() => setMessage({ message: "" })}
						/>
					</div>
				)}
			</Form>
		</Container>
	);
};

export default Update;
