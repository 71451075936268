import { Form, FormGroup } from "react-bootstrap";
import { useTranslation, Trans } from "react-i18next";

const FormSecTwo = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <FormGroup>
        <h5>{t("supportRequired")}</h5>
        <Form.Label>
          <Trans>immediateEnvSupport</Trans>
        </Form.Label>
        {/* FIXME: check why label is showing on same line as
							checkboxes and remove empty div */}
        <div></div>
        <Form.Check
          required
          inline
          label={t("yes")}
          type="radio"
          id="supportYes"
          name="supportRequired"
          checked={props.supportReq === true}
          onChange={() => props.setSupportReq(true)}
        />
        <Form.Check
          required
          inline
          label={t("no")}
          type="radio"
          id="supportNo"
          name="supportRequired"
          checked={props.supportReq === false}
          onChange={() => props.setSupportReq(false)}
        />
      </FormGroup>
      <hr />
    </>
  );
};

export default FormSecTwo;
