import { Col, Form, FormGroup, Row } from "react-bootstrap";
import { dropdownShowOther, phoneNumberAutoFormat } from "../utils/commonFunc";
import { useTranslation } from "react-i18next";
import { useState } from "react";

const FormSecOne = (props) => {
  const { t } = useTranslation();
  let company = props.company.value || "";
  if (
    company &&
    company !== "telus" &&
    company !== "bgis" &&
    company !== "standardMechanical"
  ) {
    company = "other";
  }
  const [prevNumber, setPrevNumber] = useState("");
  return (
    <>
      <FormGroup>
        <h5>{t("reporterInfo")}</h5>
        <Row>
          <Col>
            <Form.Label>{t("emailAddress")}</Form.Label>
            <Form.Control
              {...props.register("email", {
                required: {
                  value: true,
                  message: t("requiredField"),
                },
                pattern: {
                  value: /^\S+@\S+\.\S+$/,
                  message: t("emailPattern"),
                },
              })}
              type="email"
              id="email"
              placeholder="user@url.domain"
            />
            {props.errors?.email?.message && (
              <p className="text-invalid">{props.errors.email?.message}</p>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Label>{t("reporterName")}</Form.Label>
            <Form.Control
              {...props.register("reporter_name", {
                required: {
                  value: true,
                  message: t("requiredField"),
                },
              })}
              required
              type="text"
              id="reporterName"
            />
            {props.errors?.reporter_name?.message && (
              <p className="text-invalid">
                {props.errors.reporter_name?.message}
              </p>
            )}
          </Col>
          <Col>
            <Form.Label>{t("phone")}</Form.Label>
            <Form.Control
              {...props.register("phone", {
                required: {
                  value: true,
                  message: t("requiredField"),
                },
                pattern: {
                  value: /^\d{3}-\d{3}-\d{4}$/,
                  message: t("phonePattern"),
                },
                onChange: (e) => {
                  props.setValue(
                    "phone",
                    phoneNumberAutoFormat(e.target.value, prevNumber)
                  );
                  setPrevNumber(e.target.value);
                },
              })}
              required
              type="text"
              id="phone"
              placeholder="123-123-1234"
            />
            {props.errors?.phone?.message && (
              <p className="text-invalid">{props.errors.phone?.message}</p>
            )}
          </Col>
        </Row>
        <Row className="d-flex">
          <Col>
            <Form.Label>{t("company")}</Form.Label>
            <Form.Select
              required
              aria-label="company"
              value={company}
              onChange={(e) => {
                dropdownShowOther(e.target.value, props.setCompany);
              }}
            >
              <option value="">{t("chooseCompany")}</option>
              <option value="telus">TELUS Communications</option>
              <option value="bgis">BGIS</option>
              <option value="standardMechanical">Standard Mechanical</option>
              <option value="other">{t("other")}</option>
            </Form.Select>
          </Col>
          {(props.company.showOther || company === "other") && (
            <Col className="align-self-end">
              <Form.Control
                className="mt-2"
                required={props.company.showOther}
                type="text"
                placeholder={t("specifyCompany")}
                value={props.company.value || ""}
                onChange={(e) => {
                  props.setCompany((prevState) => ({
                    ...prevState,
                    value: e.target.value,
                  }));
                }}
              />
            </Col>
          )}
        </Row>
      </FormGroup>
      <hr />
    </>
  );
};

export default FormSecOne;
