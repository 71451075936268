// css imports
import "bootstrap/dist/css/bootstrap.min.css";
import "@telus-uds/palette-allium/build/web/fonts/fonts-cdn.css";
import "./lib/main.css";
// library/component imports
import { Container, Row, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "./components/Header";
import { Trans, withTranslation, useTranslation } from "react-i18next";

const App = () => {
	const { t } = useTranslation();

	return (
		<Container>
			<Header variant="primary" />
			<Row className="mt-4 d-flex flex-row justify-content-center">
				<Link to={`report/`} className="link-inline">
					<Button className="btn-primary-green btn-large text-tight">
						<span className="text-big-bold">{t("report")}</span>
						<br /> {t("reportBtnTxt")}
					</Button>
				</Link>
				<Link to={"update/"} className="link-inline">
					<Button className="btn-primary-green btn-large text-tight">
						<span className="text-big-bold">{t("update")}</span>
						<br /> <Trans>updateBtnTxt</Trans>
					</Button>
				</Link>
			</Row>
			<Row className="text-left mt-4 d-flex justify-content-center">
				<Trans>landingPageIntro</Trans>
				<Button
					className="btn btn-secondary-green btn-md"
					style={{ width: "60%" }}
					onClick={() => (window.location = t("spillLink"))}
				>
					{t("landingPageList01")}
				</Button>
				<Button
					className="btn btn-secondary-green btn-md"
					style={{ width: "60%" }}
					onClick={() => (window.location = t("sdsLink"))}
				>
					{t("landingPageList02")}
				</Button>
				<Button
					className="btn btn-secondary-green btn-md"
					style={{ width: "60%" }}
					onClick={() => (window.location = t("go/env"))}
				>
					{t("landingPageList03")}
				</Button>
				<p>
					<Trans i18nKey="landingPageCTA">
						<a href={t("spillEmail")}></a>
						<a href={t("envEmail")}></a>
					</Trans>
				</p>
			</Row>
		</Container>
	);
};

export default withTranslation()(App);
