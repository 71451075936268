import { Col, Form, FormGroup, Row } from "react-bootstrap";
import { dropdownShowOther } from "../utils/commonFunc";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { BrandColours } from "../lib/brandColours";
import { useTranslation } from "react-i18next";

const FormSecFour = (props) => {
  const { t } = useTranslation();

  const UnitsOfMeasure = {
    L: t("l"),
    ga: t("ga"),
    lbs: t("lbs"),
    kg: t("kg"),
    oz: t("oz"),
  };

  return (
    <>
      <FormGroup>
        <Row className="d-flex">
          <Col>
            <h5>{t("genSpillDetails")}</h5>
            <Form.Label>{t("spilledMaterial")}</Form.Label>
            {props.disabled && (
              <p className="text-help">
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  size="lg"
                  color={BrandColours.purpleEminence}
                  className="me-1"
                />
                {t("editMaterialInfo")}
              </p>
            )}
            <Form.Select
              required
              aria-label="spilledReleasedMaterial"
              value={props.material}
              onChange={(e) => {
                // dropdownShowOther(e.target.value, props.setMaterial);
                props.setMaterial(e.target.value);
              }}
              disabled={props.disabled}
            >
              <option value="">{t("chooseMaterial")}</option>
              <option value="refrigerant">{t("refrigerant")}</option>
              <option value="other">{t("other")}</option>
            </Form.Select>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Label>{t("estimatedQuantity")}</Form.Label>
            <Form.Control
              {...props.register("quantity", {
                required: {
                  value: true,
                  message: t("requiredField"),
                },
                min: {
                  value: 0,
                  message: t("nonNegativeNumber"),
                },
              })}
              required
              type="number"
              placeholder="0"
              min="0"
              step="0.001"
            />
            {props.errors?.quantity?.message && (
              <p className="text-invalid">{props.errors.quantity?.message}</p>
            )}
          </Col>
          <Col>
            <Form.Label>{t("measureUnit")}</Form.Label>
            <Form.Select
              required
              aria-label="unitOfMeasure"
              value={props.unitMeasure}
              onChange={(e) => props.setUnitMeasure(e.target.value)}
            >
              <option>{t("selectUnit")}</option>
              {Object.keys(UnitsOfMeasure).map((unit) => (
                <option key={unit} value={unit}>
                  {UnitsOfMeasure[unit]}
                </option>
              ))}
            </Form.Select>
          </Col>
        </Row>
      </FormGroup>
      <hr />
    </>
  );
};

export default FormSecFour;
