export const commonRequiredFields = [
	"email",
	"reporter_name",
	"phone",
	"company",
	"support_req",
	"incident_date",
	"incident_time",
	"site_code",
	"address",
	"city",
	"prov",
	"material",
	"quantity",
	"unit_measure",
	"root_cause",
	"is_reported",
];

export const refrigerantRequiredFields = [
	"refrigerant_type",
	"system_make",
	"system_model",
	"system_serial_num",
	"release_location",
	"charging_capacity",
	"actions_taken",
	"system_recharged",
];

export const substanceRequiredFields = [
	"substance",
	"substance_reach_nature",
	"substance_drains",
	"actions_taken_contain",
	"actions_taken_clean",
];
