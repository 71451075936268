import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef } from "react";
import { Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { BrandColours } from "../lib/brandColours";
import { dropdownShowOther } from "../utils/commonFunc";
import { useTranslation } from "react-i18next";

const RefrigerantTypes = [
  "R-22",
  "R-123",
  "R-134A",
  "R-407A",
  "R-407B",
  "R-407C",
  "R-410A",
  "R-438A",
  "MO99",
];

const FormSecFive = (props) => {
  const target = useRef(null);
  const { t } = useTranslation();

  const SubstanceTypes = {
    "Battery Acid": t("BatteryAcid"),
    Diesel: t("Diesel"),
    Propylene: t("Propylene"),
    Ethylene: t("Ethylene"),
    Gasoline: t("Gasoline"),
    Oil: t("Oil"),
    "Waste Water/Sediment Laiden Water": t("WasteSedimentwater"),
    PCB: t("PCB"),
    "Pesticide/Toxic Substance": t("PesticideToxicSubstance"),
    "Paints/Solvents": t("PaintsSolvents"),
    "Compressed Gas": t("CompressedGas"),
  };

  const toolTipNature = (props) => {
    return (
      <Tooltip id="tooltip-nature" {...props}>
        {t("toolTipNature")}
      </Tooltip>
    );
  };

  const toolTipDrain = (props) => {
    return (
      <Tooltip id="tooltip-drain" {...props}>
        {t("toolTipDrain")}
      </Tooltip>
    );
  };

  let refrigerantType = props.refrigerantType.value || "";
  if (refrigerantType !== "" && !RefrigerantTypes.includes(refrigerantType)) {
    refrigerantType = "other";
  }

  let substanceType = props.substanceType.value || "";
  if (substanceType !== "" && !(substanceType in SubstanceTypes)) {
    substanceType = "other";
  }

  return (
    <>
      <h5>{t("specificSpillDetails")}</h5>
      {/* REFRIGERANT BASED ON PREV RESPONSE */}
      {props.isRefrigerant && (
        <Form.Group>
          <Row className="d-flex">
            <Col>
              <Form.Label>{t("refrigerantType")}</Form.Label>
              <Form.Select
                required
                aria-label="refrigerantType"
                value={refrigerantType}
                onChange={(e) => {
                  dropdownShowOther(e.target.value, props.setRefrigerantType);
                }}
              >
                <option>{t("selectRefrigerantType")}</option>
                {RefrigerantTypes.map((type) => {
                  return (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  );
                })}
                <option value="other">{t("other")}</option>
              </Form.Select>
            </Col>
            <Col className="align-self-end">
              {(props.refrigerantType.showOther ||
                refrigerantType === "other") && (
                <Form.Control
                  className="mt-2"
                  required={props.refrigerantType.showOther}
                  type="text"
                  placeholder="Specify type"
                  value={props.refrigerantType.value}
                  onChange={(e) =>
                    props.setRefrigerantType((prevState) => ({
                      ...prevState,
                      value: e.target.value,
                    }))
                  }
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label>{t("systemMake")}</Form.Label>
              <Form.Control
                {...props.register("system_make", {
                  required: {
                    value: true,
                    message: t("requiredField"),
                  },
                })}
                required
                type="text"
                id="systemMake"
                // placeholder="Enter system's make"
              />
              {props.errors?.system_make?.message && (
                <p className="text-invalid">
                  {props.errors.system_make?.message}
                </p>
              )}
            </Col>
            <Col>
              <Form.Label>{t("systemModel")}</Form.Label>
              <Form.Control
                {...props.register("system_model", {
                  required: {
                    value: true,
                    message: t("requiredField"),
                  },
                })}
                required
                type="text"
                id="systemModel"
                // placeholder="Enter system's model"
              />
              {props.errors?.system_model?.message && (
                <p className="text-invalid">
                  {props.errors.system_model?.message}
                </p>
              )}
            </Col>
            <Col>
              <Form.Label>{t("systemSerialNum")}</Form.Label>
              <Form.Control
                {...props.register("system_serial_num", {
                  required: {
                    value: true,
                    message: t("requiredField"),
                  },
                })}
                required
                type="text"
                id="systemSerialNum"
                // placeholder="Enter system's serial number"
              />
              {props.errors?.system_serial_num?.message && (
                <p className="text-invalid">
                  {props.errors.system_serial_num?.message}
                </p>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label>{t("releaseLocation")}</Form.Label>
              <Form.Control
                {...props.register("release_location", {
                  required: {
                    value: true,
                    message: t("requiredField"),
                  },
                })}
                required
                type="text"
                id="releaseLocation"
                // placeholder="Enter release location"
              />
              {props.errors?.release_location?.message && (
                <p className="text-invalid">
                  {props.errors.release_location?.message}
                </p>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label>{t("chargingCapacity")}</Form.Label>
              <p className="text-help">
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  size="lg"
                  color={BrandColours.purpleEminence}
                  className="me-1"
                />
                {t("chargingCapacityInfo")}
              </p>
              <Form.Control
                {...props.register("charging_capacity", {
                  required: {
                    value: true,
                    message: t("requiredField"),
                  },
                  min: {
                    value: 0,
                    message: t("nonNegativeNumber"),
                  },
                })}
                required
                type="number"
                id="chargingCapacity"
                placeholder="0"
                min="0"
                step="0.001"
              />
              {props.errors?.charging_capacity?.message && (
                <p className="text-invalid">
                  {props.errors.charging_capacity?.message}
                </p>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label>{t("systemRecharged")}</Form.Label>
              <div></div>
              <Form.Check
                required
                inline
                label={t("yes")}
                type="radio"
                id="rechargedYes"
                name="systemRecharged"
                checked={
                  props.systemRecharged && props.systemRecharged === "yes"
                }
                onChange={() => props.setSystemRecharged("yes")}
              />
              <Form.Check
                required
                inline
                label={t("no")}
                type="radio"
                id="rechargedNo"
                name="systemRecharged"
                checked={
                  props.systemRecharged && props.systemRecharged === "no"
                }
                onChange={() => props.setSystemRecharged("no")}
              />
              <Form.Check
                required
                inline
                label={t("n/a")}
                type="radio"
                id="rechargedNa"
                name="systemRecharged"
                checked={
                  props.systemRecharged && props.systemRecharged === "n/a"
                }
                onChange={() => props.setSystemRecharged("n/a")}
              />
            </Col>
          </Row>
        </Form.Group>
      )}
      {/* OTHER SUBSTANCE BASED ON PREV RESPONSE */}
      {!props.isRefrigerant && (
        <Form.Group>
          <Row className="d-flex">
            <Col>
              <Form.Label>{t("substanceType")}</Form.Label>
              <Form.Select
                required
                aria-label="substanceType"
                value={substanceType}
                onChange={(e) => {
                  dropdownShowOther(e.target.value, props.setSubstanceType);
                }}
              >
                <option>{t("selectSubstanceType")}</option>
                {Object.keys(SubstanceTypes).map((type) => (
                  <option key={type} value={type}>
                    {SubstanceTypes[type]}
                  </option>
                ))}
                <option value="other">{t("other")}</option>
              </Form.Select>
            </Col>
            <Col className="align-self-end">
              {(props.substanceType.showOther || substanceType === "other") && (
                <Form.Control
                  className="mt-2"
                  required={props.substanceType.showOther}
                  type="text"
                  placeholder="Specify type"
                  value={props.substanceType.value || ""}
                  onChange={(e) =>
                    props.setSubstanceType((prevState) => ({
                      ...prevState,
                      value: e.target.value,
                    }))
                  }
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label>
                {t("reachNaturalEnv?")}
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={toolTipNature}
                  trigger={["hover", "focus", "click"]}
                >
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    size="lg"
                    color={BrandColours.purpleEminence}
                    ref={target}
                  />
                </OverlayTrigger>
              </Form.Label>
              <div></div>
              <Form.Check
                required
                inline
                label={t("yes")}
                type="radio"
                id="substanceReachNatureYes"
                name="substanceReachNature"
                checked={
                  "substanceReachNature" in props &&
                  props.substanceReachNature === true
                }
                onChange={() => props.setSubstanceReachNature(true)}
              />
              <Form.Check
                required
                inline
                label={t("no")}
                type="radio"
                id="substanceReachNatureNo"
                name="substanceReachNature"
                checked={
                  "substanceReachNature" in props &&
                  props.substanceReachNature === false
                }
                onChange={() => props.setSubstanceReachNature(false)}
              />
            </Col>
            <Col>
              <Form.Label>
                {t("enterDrains?")}
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={toolTipDrain}
                >
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    size="lg"
                    color={BrandColours.purpleEminence}
                  />
                </OverlayTrigger>
              </Form.Label>
              <div></div>
              <Form.Check
                required
                inline
                label={t("yes")}
                type="radio"
                id="substanceDrainsYes"
                name="substanceDrains"
                checked={
                  "substanceDrains" in props && props.substanceDrains === true
                }
                onChange={() => props.setSubstanceDrains(true)}
              />
              <Form.Check
                required
                inline
                label={t("no")}
                type="radio"
                id="substanceDrainsNo"
                name="substanceDrains"
                checked={
                  "substanceDrains" in props && props.substanceDrains === false
                }
                onChange={() => props.setSubstanceDrains(false)}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label>{t("actionsTakenContain")}</Form.Label>
              <Form.Control
                {...props.register("actions_taken_contain", {
                  required: {
                    value: true,
                    message: t("requiredField"),
                  },
                })}
                required
                type="text"
                id="actionsTakenContain"
              />
              {props.errors?.actions_taken_contain?.message && (
                <p className="text-invalid">
                  {props.errors.actions_taken_contain?.message}
                </p>
              )}
            </Col>
            <Col>
              <Form.Label>{t("actionsTakenClean")}</Form.Label>
              <Form.Control
                {...props.register("actions_taken_clean", {
                  required: {
                    value: true,
                    message: t("requiredField"),
                  },
                })}
                required
                type="text"
                id="actionsTakenClean"
              />
              {props.errors?.actions_taken_clean?.message && (
                <p className="text-invalid">
                  {props.errors.actions_taken_clean?.message}
                </p>
              )}
            </Col>
          </Row>
        </Form.Group>
      )}
      <hr />
    </>
  );
};

export default FormSecFive;
